/* Styles for the Home component */
.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(to right, #3b82f6, #14b8a6);
    text-align: center;
  }
  
  .logo {
    width: 128px;
    height: 128px;
    margin-bottom: 16px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .search-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Full width for better centering */
  }
  
  .search-input-container {
    position: relative;
    display: flex; /* Align the search bar and button side by side */
    width: 80%;
    max-width: 600px;
  }
  
  .search-input {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px 0 0 8px; /* Rounded only on the left side */
    margin-bottom: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: none;
    outline: none;
    font-size: 18px;
  }
  
  .search-icon-button {
    background-color: #3b82f6;
    border: none;
    padding: 8px 15px;
    border-radius: 0 8px 8px 0; /* Rounded only on the right side */
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -5px; /* To reduce the gap between input and button */
  }
  
  .search-icon-button:hover {
    background-color: #2563eb;
  }
  
  /* Container for buttons */
  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 600px;
    gap: 16px;
  }
  
  .search-button {
    flex: 1;
    padding: 12px;
    background-color: white;
    color: #3b82f6;
    border-radius: 8px;
    border: none;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .search-button:hover {
    background-color: #e0f2fe;
  }
  
  /* Suggestions Wrapper */
  .suggestions-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  