/* Wrapper to hold the suggestion list */
.suggestions-wrapper {
  width: 300px; /* Set width */
  height: 150px; /* Controlled height for the list */
  overflow: hidden; /* Hide overflowing items */
  background-color: transparent; /* Fully transparent background */
  border-radius: 10px; /* Rounded corners */
  border: 0px solid rgba(255, 255, 255, 0.3); /* Subtle border */
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1); /* Slight shadow */
  margin-top: 20px; /* Add space from elements above */
  position: relative;
  padding: 10px;
  font-size: 1.2rem;
  font-style: italic; /* Italicize the text */
}


/* Styling the suggestion list */
.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  animation: slide 10s linear infinite; /* Continuous sliding animation */
}

.suggestions-list li {
  padding: 10px 0;
  color: rgba(255, 255, 255, 0.8); /* Faded white text */
  font-family: 'Georgia', serif; /* Quoted style font */
  font-size: 1.2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); /* Faded white borders */
  transition: background-color 0.2s ease;
  cursor: pointer;
  text-align: center; /* Center the text */
}

/* Add ellipses before each suggestion */
.suggestions-list li::before {
  content: '" '; /* Add ellipses */
  font-size: 1.2rem;
}

.suggestions-list li::after {
  content: ' "';
}

/* Hover effect */
.suggestions-list li:hover {
  background-color: rgba(255, 255, 255, 0.15); /* Slightly brighter on hover */
}

/* Keyframes for the slide animation */
@keyframes slide {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
